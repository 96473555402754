<template>
    <div class="c-item" :class="bgColor">
        <div class="top">
            <div class="icon">
                <img :src="require(`@/assets/${icon}`)" alt="">
            </div>
            <p class="title">{{ title }}</p>
        </div>
        <div class="body">
            <p class="first-value">
                <span class="num">{{ firstNum }}</span>
                {{ firstText }}
            </p>
            <p class="second-value" v-if="typeof secondNum === 'number'">
                <span class="num">{{ secondNum }}</span>
                {{ secondText }}
            </p>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        icon: String,
        title: String,
        multiLines: Boolean,
        firstNum: [String, Number],
        firstText: [String, Number],
        secondNum: [String, Number],
        secondText: [String, Number],
        bgColor: String,
    },
    data: function() {
        return {}
    }
}

</script>

<style lang="sass">

div.c-item
    display: flex
    flex-direction: column
    justify-content: space-between
    border-radius: 24px
    margin-bottom: 8px
    min-height: 368px
    font-weight: 700
    width: 49%

    div.top
        display: flex
        align-items: center

    div.body
        padding: 32px 32px 32px 44px

    p.title
        font-weight: bold
        font-size: 24px
        line-height: 36px
        letter-spacing: 0.02em
        text-transform: uppercase
        color: #737373
        margin: 0
        margin-left: 20px

    p.first-value,
    p.second-value
        font-weight: bold
        font-size: 25px
        line-height: 52px
        color: #111111
        margin: 0

    p.second-value
        position: relative
        &:before
            content: ""
            display: block
            background: #fff
            height: 2px
            width: 80px
            position: absolute
            top: 0
            left: 0
            

    span.num
        font-size: 90px
        line-height: 105px
        color: #111111

    &.yellow
        background: linear-gradient(280.19deg, #EAB818 1.06%, rgba(255, 255, 255, 0.64) 100%)
    &.green
        background: linear-gradient(280.19deg, #B1C333 0%, rgba(255, 255, 255, 0.64) 100%)
        backdrop-filter: blur(4px)
    &.blue
        background: linear-gradient(280.19deg, rgba(0, 136, 255, 0.5) 0%, rgba(255, 255, 255, 0.32) 100%)
        backdrop-filter: blur(4px)
    &.pink
        background: linear-gradient(280.19deg, #E5B2C0 0%, rgba(255, 255, 255, 0.64) 100%)
        backdrop-filter: blur(4px)

</style>