<template>
    <header class="head">
        <div class="left">
            <img src="@/assets/logo.png" alt="logo">
            <span class="text">GSI Accountability Dashboard</span>
        </div>
        <div class="right">
            <span class="weeks">Week {{ currentWeek }}</span>
            <span class="date">{{ weekDay }} {{ day }} {{ month }}</span>
            <span class="time">{{ hours }}:{{ minutes }}</span>
        </div>
    </header>
</template>

<script>

export default {
    data: function() {
        return {
            currentWeek: 0,
            hours: 0,
            minutes: 0,
            month: "",
            day: "",
            weekDay: "",
        }
    },
    mounted() {
        let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
        let dayNames = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"]
        let date = new Date()

        this.weekDay = dayNames[date.getDay()]
        this.day = date.getDate()
        this.month = monthNames[date.getMonth()]

        // Current Week Number
        let oneJan = new Date(date.getFullYear(),0,1)
        let numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000))
        this.currentWeek = Math.ceil(( date.getDay() + 1 + numberOfDays) / 7)
        

        this.updateTime()
        setInterval(() => this.updateTime(), 30000)
    },
    beforeDestroy() {
        clearInterval(this.updateTime)
    },
    methods: {
        updateTime() {
            let date = new Date()

            this.hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
            this.minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
        }
    },
}

</script>

<style lang="sass">

header.head
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 15px

    div.left
        display: flex
        align-items: center

    span.text
        display: inline-block
        font-size: 20px
        line-height: 100%
        color: #737373
        letter-spacing: 0.04em
        text-transform: uppercase
        padding: 5px 0 5px 16px
        border-left: 1px solid #737373
        margin-left: 16px

    span.weeks
        font-size: 20px
        line-height: 32px
        letter-spacing: 0.02em
        text-transform: uppercase
        color: #737373
        font-weight: bold

    span.date
        font-size: 24px
        line-height: 32px
        color: #292A6B
        padding-left: 16px
        margin-left: 16px
        border-left: 1px solid #737373

    span.time
        font-weight: 900
        font-size: 24px
        line-height: 32px
        color: #292A6B
        margin-left: 16px

</style>