<template>
    <div class="v-dashboard">
        <div class="spinner-wrap" v-show="isDataLoading">
            <Spinner />
        </div>
        <div class="container" v-show="!isDataLoading">
            <div class="left">
                <Header />
                <div class="items">
                    <Item
                        v-for="item in items"
                        :key="item.id"
                        :icon="item.icon"
                        :title="item.title"
                        :bgColor="item.bgColor"
                        :firstNum="item.firstNum"
                        :secondNum="item.secondNum"
                        :firstText="item.firstText"
                        :secondText="item.secondText"
                    />
                </div>
                <h3 class="jobs-title">Jobs by customers</h3>
                <div class="lists">
                    <!-- <List
                        v-for="list in customersJobs"
                        :key="list.id"
                        :items="list.items"
                    /> -->
                    <div class="list-item">
                        <div
                            v-for="item in customersJobs"
                            :key="item.id"
                            class="row"
                        >
                            <span class="text">{{ item.name }}</span>
                            <div class="line"></div>
                            <span class="value">{{ item.jobsNumber }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <img src="@/assets/bg1.jpg" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
// Libs
import { nanoid } from "nanoid"
// Components
import Header from "./components/Header"
import Item from "./components/Item"
import List from "./components/List"
import Spinner from "../../components/Spinner"

export default {
    data: function() {
        return {
            isDataLoading: true,
            customersJobs: [],
            items: [
                {
                    id: nanoid(),
                    icon: "building-icon.svg",
                    title: "Billable Hours",
                    firstNum: "",
                    secondNum: "",
                    firstText: "Billable hours",
                    secondText: "Total hours",
                    bgColor: "yellow",
                },
                {
                    id: nanoid(),
                    icon: "jobs-icon.svg",
                    title: "Completed Jobs",
                    firstNum: "",
                    secondNum: "",
                    firstText: "",
                    secondText: "",
                    bgColor: "green",
                },
                {
                    id: nanoid(),
                    icon: "builder-icon.svg",
                    title: "Employees",
                    firstNum: "",
                    secondNum: "",
                    firstText: "in Rotation",
                    secondText: "Available",
                    bgColor: "blue",
                },
                {
                    id: nanoid(),
                    icon: "ship.svg",
                    title: "Jobs",
                    firstNum: "",
                    secondNum: "",
                    firstText: "Running",
                    secondText: "Pending",
                    bgColor: "pink",
                },
            ],
            interval: 0,
        }
    },
    components: {
        Header,
        Item,
        List,
        Spinner,
    },
    async mounted() {
        await this.updateData()
        this.interval = window.setInterval(async () => await this.updateData(), 6000)
    },
    beforeDestroy() {
        window.clearInterval(this.interval)
    },
    methods: {
        async updateData() {
            try {
                // let url = "http://localhost:5000/api/dashboard"
                let url = "https://www.gulfstarindscripts.com/api/dashboard"

                let response = await fetch(url)

                response = await response.json()

                console.log(response)

                this.items[0].firstNum = response.billableHours
                this.items[0].secondNum = response.totalHours
                this.items[1].firstNum = response.completedJobs
                this.items[2].firstNum = response.employees.rotation
                this.items[2].secondNum = response.employees.available
                this.items[3].firstNum = response.jobs.running
                this.items[3].secondNum = response.jobs.pending

                // Customers Jobs
                this.customersJobs = response.customersJobs

                this.isDataLoading = false
            } catch (err) {
                console.error(err)
            }
        }
    }
}

</script>

<style lang="sass">

div.v-dashboard

    div.spinner-wrap
        width: 100%
        height: 100vh
        display: flex
        justify-content: center
        align-items: center

    div.container
        display: flex

    h3.jobs-title
        text-align: center
        font-size: 25px
        color: #292A6B
        margin: 10px 0 10px 0
        text-transform: uppercase
    
    // div.lists
    //     display: flex
    //     align-items: center
    //     justify-content: center

    div.items
        display: flex
        flex-wrap: wrap
        justify-content: space-between

    div.right
        padding-left: 10px

    div.list-item
        display: flex
        flex-direction: column
        flex-wrap: wrap
        max-height: 203px
    
        div.row
            display: flex
            align-items: center
            justify-content: space-between
            margin: 5px
        
        span.text
            font-size: 16px
            line-height: 19px
            letter-spacing: 0.02em
            color: #111111

        div.line
            flex-grow: 1
            border: 1px dashed #111111
            opacity: 0.32
            margin: 0 12px

        span.value
            font-weight: 500
            font-size: 24px
            line-height: 28px
            color: #292A6B

</style>