<template>
    <div class="list-item">
        <div
            v-for="item in items"
            :key="item.id"
            class="row"
        >
            <span class="text">{{ item.text }}</span>
            <div class="line"></div>
            <span class="value">{{ item.value }}</span>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        items: Array,
    },
    data: function() {
        return {}
    }
}

</script>

<style lang="sass">

div.list-item
    // width: 30%
    
    div.row
        display: flex
        align-items: center
        justify-content: space-between
        margin: 5px 0
    
    span.text
        font-size: 16px
        line-height: 19px
        letter-spacing: 0.02em
        color: #111111

    div.line
        flex-grow: 1
        border: 1px dashed #111111
        opacity: 0.32
        margin: 0 12px

    span.value
        font-weight: 500
        font-size: 24px
        line-height: 28px
        color: #292A6B

</style>