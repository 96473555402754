<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
    metaInfo: {
        meta: [
            { name: "viewport", content: "width=device-width, initial-scale=1" }
        ]
    }
}

</script>


<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap')

html,
body
    margin: 0
    padding: 0

    *
        box-sizing: border-box
        font-family: "Roboto"

div#app
    zoom: 80%

div.container
    max-width: 1600px
    margin: 0 auto

</style>
